import LoadingSpinner from "../../../icons/LoadingSpinner";
import TextField from "../../TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrain } from "@fortawesome/pro-regular-svg-icons";
import SingleSelect from "../../SingleSelect";
import { useEffect } from "react";

const TrainField = ({
  vehicle,
  occurrencePayload,
  setOccurrencePayload,
  handleVehicleLookUp,
  searchingVehicle,
  setVehicleSearchPayload,
  vehicleSearchPayload,
  isRequired,
  manageOccurrences,
  selectedOperator,
}) => {
  const vehicleCarrierCodes = selectedOperator?.value?.vehicle_carrier_codes || selectedOperator?.vehicle_carrier_codes || [];

  const updateVehicleSearchPayload = (resetVehicle = false) => {
    const newPayload = {
      ...vehicleSearchPayload,
      carrier_code: "",
      serial_number: "",
    };

    if (vehicleCarrierCodes?.length === 1) {
      newPayload.carrier_code = {
        label: vehicleCarrierCodes[0],
        value: vehicleCarrierCodes[0],
      };
    }

    setVehicleSearchPayload(newPayload);

    if (resetVehicle) {
      setOccurrencePayload((prevState) => ({ ...prevState, vehicle: "" }));
    }
  };

  useEffect(() => {
    if (!selectedOperator) {
      updateVehicleSearchPayload(true);
    } else {
      updateVehicleSearchPayload();
    }
  }, [selectedOperator]);

  return (
    <div className="form-control">
      {manageOccurrences ? (
        <div className="flex gap-4 items-center">
          <div className="w-32">
            <label className="font-bold">
              {" "}
              <span className="label-text">
                Vehicle
                {isRequired && <span className="text-error"> *</span>}
              </span>
            </label>
          </div>
          {occurrencePayload.vehicle && occurrencePayload.vehicle !== "" && (
            <button
              className="btn btn-sm btn-outline btn-error"
              onClick={() => {
                updateVehicleSearchPayload(true);
              }}
            >
              Unselect Vehicle
            </button>
          )}
          <div>
            {occurrencePayload.vehicle && occurrencePayload.vehicle !== "" ? (
              <div className="card card-compact p-3 border border-base-300 bg-[#F2F5F7]">
                <div className="flex justify-between gap-10">
                  <div className="flex gap-5">
                    <div>
                      <div>
                        <FontAwesomeIcon icon={faTrain} />{" "}
                        {occurrencePayload.vehicle.carrier_code}{" "}
                        {occurrencePayload.vehicle.serial_number}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex gap-2">
                <div className="join">
                  <div className="join-item w-full">
                    <SingleSelect
                      isDisabled={searchingVehicle || !selectedOperator || vehicleCarrierCodes?.length <= 1}
                      placeholder={"Carrier Code"}
                      value={vehicleSearchPayload.carrier_code}
                      options={vehicleCarrierCodes?.map((carrier_code) => ({
                        label: carrier_code,
                        value: carrier_code,
                      }))}
                      handleOnChange={(selectedOption) =>
                        setVehicleSearchPayload({
                          ...vehicleSearchPayload,
                          carrier_code: selectedOption,
                        })
                      }
                    />
                  </div>
                  <TextField
                    disabled={searchingVehicle || !selectedOperator}
                    className="join-item w-full"
                    placeholder={"Serial #"}
                    value={vehicleSearchPayload.serial_number}
                    handleOnChange={(event) =>
                      setVehicleSearchPayload({
                        ...vehicleSearchPayload,
                        serial_number: event.target.value,
                      })
                    }
                  />
                </div>

                <button
                  disabled={searchingVehicle || !selectedOperator}
                  className="btn btn-sm btn-primary lg:btn-md"
                  onClick={() => handleVehicleLookUp()}
                >
                  Search
                  {searchingVehicle && <LoadingSpinner />}
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <label className="label">
            <span className="label-text">
              Vehicle {isRequired && <span className="text-error">*</span>}
            </span>
            {occurrencePayload.vehicle && occurrencePayload.vehicle !== "" && (
              <button
                className="btn btn-sm btn-outline btn-error"
                onClick={() => {
                  updateVehicleSearchPayload(true);
                }}
              >
                Unselect Vehicle
              </button>
            )}
          </label>
          {occurrencePayload.vehicle && occurrencePayload.vehicle !== "" ? (
            <div className="card card-compact p-3 border border-base-300 bg-[#F2F5F7]">
              <div className="flex justify-between gap-10">
                <div className="flex gap-5">
                  <div>
                    <div>
                      <FontAwesomeIcon icon={faTrain} />{" "}
                      {occurrencePayload.vehicle.carrier_code}{" "}
                      {occurrencePayload.vehicle.serial_number}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex gap-2">
              <div className="join">
                <div className="join-item w-full">
                  <SingleSelect
                    isDisabled={searchingVehicle || !selectedOperator || vehicleCarrierCodes?.length <= 1}
                    placeholder={"Carrier Code"}
                    value={vehicleSearchPayload.carrier_code}
                    options={vehicleCarrierCodes?.map((carrier_code) => ({
                      label: carrier_code,
                      value: carrier_code,
                    }))}
                    handleOnChange={(selectedOption) =>
                      setVehicleSearchPayload({
                        ...vehicleSearchPayload,
                        carrier_code: selectedOption,
                      })
                    }
                  />
                </div>
                <TextField
                  className="join-item w-full"
                  placeholder={"Serial #"}
                  value={vehicleSearchPayload.serial_number}
                  handleOnChange={(event) =>
                    setVehicleSearchPayload({
                      ...vehicleSearchPayload,
                      serial_number: event.target.value,
                    })
                  }
                />
              </div>

              <button
                disabled={searchingVehicle}
                className="btn btn-sm btn-primary lg:btn-md"
                onClick={() => handleVehicleLookUp()}
              >
                Search
                {searchingVehicle && <LoadingSpinner />}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
};

export default TrainField;
