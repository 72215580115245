import SingleSelect from "../SingleSelect";
import DateRangePicker from "../DateRangePicker";
import TextField from "../TextField";
import LoadingSpinner from "../../icons/LoadingSpinner";
import Checkbox from "../Checkbox";
import { useState, useContext, useEffect } from "react";
import { useFetchFunctions } from "../../services/trips";
import "react-datepicker/dist/react-datepicker.css";
import { useQueries } from "@tanstack/react-query";
import { format } from "date-fns-tz";
import { OccurrenceSearchPayload } from "../../types/occurrences";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/pro-regular-svg-icons";
import Pagination from "../Pagination";
import ManageOccurrencesTable from "./ManageOccurrencesTable";
import { defaultOccurrenceSearchPayload, WAIVER__STATUS_OPTIONS } from "./constants";
import { NavigationContext } from "../../utils/Contexts";
import DynamicOccurrenceCauseFormFields from "../trip-detail/trip-occurrences/DynamicOccurrenceCauseFormFields";
import DynamicFormFields from "../trip-detail/trip-occurrences/DynamicFormFields";
import TripOccurrenceFlyout from "../trip-detail/trip-occurrences/TripOccurrenceFlyout";
import UnusualOccurrenceFlyout from "./UnusualOccurrenceFlyout";
import Toast from "../Toast";

const handleValuesFieldChange = (
  field,
  value,
  setPayload,
  setPayloadForEdit,
  originalValues
) => {
  setPayload((prev) => ({
    ...prev,
    [field]: value,
  }));
};

const formatStationaryAssets = (stationaryAssets) => {
  const formattedArray = [];

  // Process locations
  stationaryAssets?.data?.locations?.forEach((location) => {
    const { id, name, stationary_assets } = location;
    if (stationary_assets && stationary_assets.length > 0) {
      formattedArray.push({ id, location_name: name, assets: stationary_assets });
    }
  });

  // Process location_links
  stationaryAssets?.data?.location_links?.forEach((link) => {
    const { id, group, stationary_assets } = link;
    if (stationary_assets && stationary_assets.length > 0) {
      formattedArray.push({ id, location_name: group, assets: stationary_assets });
    }
  });

  return { data: formattedArray, isLoading: stationaryAssets.isLoading };
};

export default function ManageOccurrences() {
  const [searchPayload, setSearchPayload] = useState<OccurrenceSearchPayload>(
    defaultOccurrenceSearchPayload
  );
  const [selectedOperator, setSelectedOperator] = useState("");
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [searchResults, setSearchResults] = useState({});
  const [searchResultsTab, setSearchResultsTab] = useState("delay");
  const [delaysDrawerIsOpen, setDelaysDrawerIsOpen] = useState(false);
  const [unusualOccurrencesDrawerIsOpen, setUnusualOccurrencesDrawerIsOpen] =
    useState(false);
  const [vehicleSearchPayload, setVehicleSearchPayload] = useState("");
  const [searchingVehicle, setSearchingVehicle] = useState(false);
  const [occurrenceCauseFields, setOccurrenceCauseFields] = useState([]);
  const [fetchingForEdit, setFetchingForEdit] = useState(false);

  // "values" advanced fields payloads
  const [
    operatorTripOccurrenceFieldsPayload,
    setOperatorTripOccurrenceFieldsPayload,
  ] = useState({});
  const [operatorTripFieldsPayload, setOperatorTripFieldsPayload] = useState({});
  const [occurrenceCauseValuesPayload, setOccurrenceCauseValuesPayload] = useState(
    {}
  );

  //form flyout states
  const [occurrenceDrawer, setOccurrenceDrawer] = useState(false);
  const [occurrenceToBeEditedProp, setOccurrenceToBeEditedProp] = useState(null);
  const [occurrenceType, setOccurrenceType] = useState(null);

  const handleFetchTripOccurrence = async (trip_occurrence) => {
    const { service_date, trip_name, trip_occurrence_id } = trip_occurrence;

    const formattedServiceDate = format(new Date(service_date), "yyyy-MM-dd");

    try {
      await fetchTripOccurrence(
        selectedOperator?.value?.short_name,
        formattedServiceDate,
        trip_name,
        trip_occurrence_id
      ).then((res) => {
        if (!res.error) {
          setOccurrenceToBeEditedProp({
            ...res,
            service_date: formattedServiceDate,
            trip_name: trip_name,
          });
        }
        setFetchingForEdit(false);
      });
    } catch (error) {
      console.log("Error:", error);
      setFetchingForEdit(false);
    }
  };
  const handleFetchUnusualOccurrence = async (occurrence) => {
    const { start_date, occurrence_id } = occurrence;

    const formattedServiceDate = format(new Date(start_date), "yyyy-MM-dd");

    try {
      await fetchOccurrence(selectedOperator?.value?.short_name, occurrence_id).then(
        (res) => {
          if (!res.error) {
            setOccurrenceToBeEditedProp({
              ...res,
              service_date: formattedServiceDate,
              occurrence_id: occurrence_id,
            });
          }
          setFetchingForEdit(false);
        }
      );
    } catch (error) {
      console.log("Error:", error);
      setFetchingForEdit(false);
    }
  };

  const handleClickTableRow = (type, data) => {
    setOccurrenceType(type);
    setOccurrenceDrawer(true);
    setFetchingForEdit(true);
    if (type === "delay") {
      handleFetchTripOccurrence(data);
    }
    if (type === "unusual_occurrence") {
      handleFetchUnusualOccurrence(data);
    }
  };

  const { currentNavigationContext, setCurrentNavigationContext } =
    useContext(NavigationContext);

  useEffect(() => {
    setCurrentNavigationContext("manage-occurrences");
  }, []);

  const {
    fetchOperators,
    fetchScheduleTypes,
    searchOccurrences,
    fetchResponsibleParties,
    fetchOperatorOccurrenceCauses,
    fetchLinearAssetsByTransportationMode,
    fetchStationaryAssetsByLinearAsset,
    fetchLocationsByLinearAsset,
    fetchVehicle,
    fetchOperatorTripFields,
    fetchOperatorTripOccurrenceFields,
    editOccurrence,
    fetchTripOccurrence,
    fetchOccurrence,
  } = useFetchFunctions();

  const results = useQueries({
    queries: [
      {
        queryKey: ["operators"],
        queryFn: () => fetchOperators(),
        staleTime: 1000 * 10,
      },
      {
        queryKey: ["schedule_types"],
        queryFn: () => fetchScheduleTypes(),
        staleTime: 1000 * 10,
      },
      {
        queryKey: ["operator_trip_fields", selectedOperator?.value?.short_name],
        queryFn: () => fetchOperatorTripFields(selectedOperator?.value?.short_name),
        staleTime: 1000 * 10,
        enabled: !!selectedOperator,
      },
      {
        queryKey: ["responsible_parties"],
        queryFn: () => fetchResponsibleParties(),
        staleTime: 1000 * 10,
      },
      {
        queryKey: [
          "operator_occurrence_causes",
          selectedOperator?.value?.short_name,
        ],
        queryFn: () =>
          fetchOperatorOccurrenceCauses(selectedOperator?.value?.short_name),
        staleTime: 1000 * 10,
        enabled: !!selectedOperator,
      },
      {
        queryKey: ["linear_assets", selectedOperator?.value?.short_name],
        queryFn: () =>
          fetchLinearAssetsByTransportationMode(
            selectedOperator?.value?.transportation_mode?.abbreviation
          ),
        staleTime: 0,
        enabled: !!selectedOperator?.value?.short_name,
      },
      {
        queryKey: [
          "stationary_assets",
          searchPayload?.linear_asset_id?.value?.asset_id,
        ],
        queryFn: () =>
          fetchStationaryAssetsByLinearAsset(
            searchPayload?.linear_asset_id?.value?.id,
            searchPayload?.occurrence_cause?.value?.stationary_asset_type
          ),
        staleTime: 0,
        enabled:
          !!searchPayload?.linear_asset_id?.value?.id &&
          !!searchPayload?.occurrence_cause?.value?.stationary_asset,
      },
      {
        queryKey: ["locations", searchPayload?.linear_asset_id?.value?.id],
        queryFn: () =>
          fetchLocationsByLinearAsset(searchPayload?.linear_asset_id?.value?.id),
        staleTime: 0,
        enabled: !!searchPayload?.linear_asset_id?.value?.id,
      },
      {
        queryKey: [
          "operator_trip_occurrence_fields",
          selectedOperator?.value?.short_name,
        ],
        queryFn: () =>
          fetchOperatorTripOccurrenceFields(selectedOperator?.value?.short_name),
        staleTime: 1000 * 10,
        enabled: !!selectedOperator,
      },
    ],
  });

  const operators = results[0];
  const scheduleTypes = results[1];
  const operatorTripFields = results[2];
  const responsibleParties = results[3];
  const operatorOccurrenceCauses = results[4];
  const linearAssets = results[5];
  const stationaryAssets = results[6];
  const locations = results[7];
  const operatorTripOccurrenceFields = results[8];

  const loadingAdvancedFilters = [
    operatorTripFields,
    operatorTripOccurrenceFields,
  ].some((result) => result.isLoading);

  const combinedFormFields = [
    ...(operatorTripOccurrenceFields?.data ?? []).map((field) => ({
      ...field.trip_occurrence_field,
      category: "operatorTripOccurrenceFields",
    })),
    ...(operatorTripFields?.data ?? []).map((field) => ({
      ...field.trip_field,
      lookup_list_items: field.lookup_list_items,
      category: "operatorTripFields",
    })),
    ...(occurrenceCauseFields ?? []).map((field) => ({
      ...field,
      category: "occurrenceCauseFields",
    })),
  ];

  const handleVehicleLookUp = async () => {
    setSearchingVehicle(true);

    try {
      await fetchVehicle(
        selectedOperator?.value?.short_name,
        vehicleSearchPayload?.carrier_code?.value,
        vehicleSearchPayload?.serial_number
      ).then((res) => {
        if (!res.error) {
          // if (isEditMode) {
          //   setOccurrenceToBeEdited((prevOccurrenceToBeEdited) => ({
          //     ...prevOccurrenceToBeEdited,
          //     vehicle: res,
          //   }));
          // } else {
          setSearchPayload((prev) => ({
            ...prev,
            vehicle: res,
          }));
          // }
        }
        setSearchingVehicle(false);
      });
    } catch (error) {
      console.log("Error:", error);
      setSearchingVehicle(false);
    }
  };

  const handleChangeDate = (from_service_date: Date, to_service_date: Date) => {
    setSearchPayload((prev) => ({
      ...prev,
      from_service_date,
      to_service_date,
    }));
  };

  const handleNewTripValue = (
    field_name: string,
    newValue: string | boolean | string[] | undefined
  ) => {
    if (newValue === "") {
      newValue = undefined;
    }
    const newTripValues = [
      ...searchPayload.trip_values.filter(
        (trip_value) => trip_value.field_name !== field_name
      ),
      {
        field_name,
        value: newValue,
      },
    ];
    setSearchPayload({
      ...searchPayload,
      trip_values: newTripValues,
    });
  };

  const handleFieldChange = (field, value, category) => {
    switch (category) {
      case "operatorTripOccurrenceFields":
        handleValuesFieldChange(
          field,
          value,
          setOperatorTripOccurrenceFieldsPayload,
          {},
          {}
        );
        break;
      case "operatorTripFields":
        handleValuesFieldChange(field, value, setOperatorTripFieldsPayload, {}, {});
        break;
      case "occurrenceCauseFields":
        handleValuesFieldChange(
          field,
          value,
          setOccurrenceCauseValuesPayload,
          {},
          {}
        );
        break;
      default:
        break;
    }
  };

  const handleSearch = async (tab) => {
    const operator_short_name = selectedOperator.value.short_name;
    const searchJson = JSON.parse(JSON.stringify(searchPayload));

    const operatorTripOccurrenceFieldsJson = JSON.parse(
      JSON.stringify(operatorTripOccurrenceFieldsPayload)
    );
    const operatorTripFieldsJson = JSON.parse(
      JSON.stringify(operatorTripFieldsPayload)
    );
    const occurrenceCauseValuesJson = JSON.parse(
      JSON.stringify(occurrenceCauseValuesPayload)
    );

    const convertValuesToPayloadFormat = (
      occurrenceCauseValues,
      tripOccurrenceValues,
      tripValues
    ) => {
      const convert = (input) => {
        return Object.entries(input).map(([key, value]) => {
          if (Array.isArray(value)) {
            // Check if the array contains objects with 'value' and 'label' properties
            const isObjectArray = value.every(
              (item) =>
                typeof item === "object" && "value" in item && "label" in item
            );

            if (isObjectArray) {
              // Extract the 'value' property from each object in the array
              value = value.map((item) => item.value);
            }
          } else if (
            typeof value === "object" &&
            "value" in value &&
            "label" in value
          ) {
            // Handle single object with 'value' and 'label' properties
            value = [value.value];
          } else {
            // Ensure the value is an array
            value = [value];
          }

          return {
            name: key,
            value,
          };
        });
      };

      const occurrence_cause_values = convert(occurrenceCauseValues);
      const trip_occurrence_values = convert(tripOccurrenceValues);
      const trip_values = convert(tripValues);

      const result = {};
      if (occurrence_cause_values.length > 0) {
        result.occurrence_cause_values = occurrence_cause_values;
      }
      if (trip_occurrence_values.length > 0) {
        result.trip_occurrence_values = trip_occurrence_values;
      }
      if (trip_values.length > 0) {
        result.trip_values = trip_values;
      }

      return result;
    };

    const convertedValues = convertValuesToPayloadFormat(
      occurrenceCauseValuesJson,
      operatorTripOccurrenceFieldsJson,
      operatorTripFieldsJson
    );

    const formattedPayload = {
      responsible_party_id: searchJson.responsible_party_id?.value,
      from_service_date: format(searchJson.from_service_date, "yyyy-MM-dd"),
      to_service_date: format(searchJson.to_service_date, "yyyy-MM-dd"),
      occurrence_cause_id: searchJson.occurrence_cause?.value?.id,
      waiver_status: searchJson?.waiver_status?.value,
      primary: searchJson?.primary,
      trip_name: searchJson?.trip_name,
      linear_asset_id: searchJson.linear_asset_id?.value?.id,
      stationary_asset_id: searchJson.stationary_asset?.value?.id,
      location_id: searchJson.location?.value?.id,
      search_type: tab ?? searchResultsTab,
      vehicle_id: searchJson.vehicle?.id,
      ...convertedValues,
    };

    const cleanedPayload = Object.fromEntries(
      Object.entries(formattedPayload).filter(
        ([_, value]) => value !== "" && value !== null && value !== undefined
      )
    );

    setLoadingSearchResults(true);
    try {
      await searchOccurrences(operator_short_name, cleanedPayload).then((res) => {
        setSearchResults(res);
        setLoadingSearchResults(false);
        if (tab) {
          setSearchResultsTab(tab);
        }
      });
    } catch (error) {
      console.log(error);
      setLoadingSearchResults(false);
    }
  };

  const handleResetSearch = () => {
    setSelectedOperator("");
    setSearchPayload(defaultOccurrenceSearchPayload);
    setOperatorTripOccurrenceFieldsPayload({});
    setOperatorTripFieldsPayload({});
    setOccurrenceCauseValuesPayload({});
    setOccurrenceCauseFields([]);
    setVehicleSearchPayload("");
    setLoadingSearchResults(false);
    setSearchResults({});
  };

  const handleSetSearchResultsTab = (tab) => {
    if (selectedOperator && !loadingSearchResults) {
      handleSearch(tab);
    }
  };

  const handleChangePage = (pageNumber, resultsPerPage) => {
    setSearchPayload({
      ...searchPayload,
      page: pageNumber,
      page_size: resultsPerPage,
    });
    handleSearch(selectedOperator.value, {
      ...searchPayload,
      page: pageNumber,
      page_size: resultsPerPage,
    });
  };

  const handleChangeSort = (sort, sort_by) => {
    setSearchPayload({
      ...searchPayload,
      sort: sort,
      sort_by: sort_by,
    });
    handleSearch(selectedOperator.value, {
      ...searchPayload,
      sort: sort,
      sort_by: sort_by,
    });
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (!selectedOperator && !operators.isLoading && operators.data) {
      setSelectedOperator({ value: operators.data[0], label: operators.data[0].name });
    }
  }, [selectedOperator, operators.isLoading]);

  return (
    <div>
      <Toast />
      <div className="drawer drawer-end">
        <input
          id="add-occurrence-drawer"
          type="checkbox"
          className="drawer-toggle"
          checked={occurrenceDrawer}
          onChange={() => { }}
        />
        {occurrenceType === "delay" && (
          <TripOccurrenceFlyout
            operator_short_name={selectedOperator?.value?.short_name}
            service_date={occurrenceToBeEditedProp?.service_date}
            trip={{ trip_name: occurrenceToBeEditedProp?.trip_name }}
            occurrenceDrawer={occurrenceDrawer}
            setOccurrenceDrawer={setOccurrenceDrawer}
            occurrenceToBeEditedProp={occurrenceToBeEditedProp}
            setOccurrenceToBeEditedProp={setOccurrenceToBeEditedProp}
            isPrimary={occurrenceToBeEditedProp?.trip_occurrence_primary}
            fetchingForEdit={fetchingForEdit}
          />
        )}
        {occurrenceType === "unusual_occurrence" && (
          <UnusualOccurrenceFlyout
            operator_short_name={selectedOperator?.value?.short_name}
            unusualOccurrenceDrawerIsOpen={occurrenceDrawer}
            setUnusualOccurrenceDrawerIsOpen={setOccurrenceDrawer}
            occurrenceToBeEditedProp={occurrenceToBeEditedProp}
            setOccurrenceToBeEditedProp={setOccurrenceToBeEditedProp}
            fetchingForEdit={fetchingForEdit}
          />
        )}
      </div>

      <div className="page-header bg-info lg:flex justify-between px-4 py-5">
        <div className="md:flex gap-4 items-center prose text-white">
          <h1 className="flex gap-3 items-center m-0 text-2xl lg:text-4xl text-white">
            Manage Occurrences
          </h1>
        </div>
        <div className="breadcrumbs text-white hidden md:block">
          <ul>
            <li className="text-white/50">Manage Occurrences</li>
          </ul>
        </div>
      </div>

      <div className="p-5">
        <div className="w-full">
          <div className="card card-compact bg-base-100 border border-base-300 prose">
            <div className="card-body gap-4">
              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">
                    Operator<span className="text-error"> *</span>
                  </label>
                </div>
                <SingleSelect
                  placeholder={"Select operator..."}
                  value={selectedOperator}
                  isDisabled={operators.isLoading || operators.data?.length <= 1}
                  isLoading={operators.isLoading}
                  handleOnChange={(o) => setSelectedOperator(o)}
                  options={
                    operators &&
                    Array.isArray(operators?.data) &&
                    operators?.data?.map((operator) => {
                      return { value: operator, label: operator.name };
                    })
                  }
                />
              </div>
              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">
                    Service Date<span className="text-error"> *</span>
                  </label>
                </div>
                <DateRangePicker
                  startDate={searchPayload.from_service_date}
                  endDate={searchPayload.to_service_date}
                  handleChangeDate={handleChangeDate}
                />
              </div>
              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Responsible Party</label>
                </div>
                <div className="w-60">
                  <SingleSelect
                    placeholder={"Select"}
                    value={searchPayload.responsible_party_id}
                    isLoading={responsibleParties.isLoading}
                    isDisabled={responsibleParties.isLoading || !selectedOperator}
                    handleOnChange={(party) => {
                      setSearchPayload({
                        ...searchPayload,
                        responsible_party_id: party,
                      });
                    }}
                    options={
                      responsibleParties &&
                      Array.isArray(responsibleParties?.data) &&
                      responsibleParties?.data?.map((party) => {
                        return { value: party.id, label: party.name };
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Occurrence</label>
                </div>
                <div className="flex gap-3 flex-wrap">
                  <div className="w-60">
                    <SingleSelect
                      placeholder={"Delay Cause"}
                      isDisabled={
                        operatorOccurrenceCauses?.isLoading || !selectedOperator
                      }
                      isLoading={operatorOccurrenceCauses?.isLoading}
                      useFormatGroupLabel={true}
                      value={searchPayload.occurrence_cause}
                      handleOnChange={(occurrence_cause) => {
                        let occurrenceCause;

                        if (!occurrence_cause) {
                          occurrenceCause = {
                            value: "",
                            label: "Delay Cause",
                          };
                        } else {
                          occurrenceCause = {
                            value: occurrence_cause?.value,
                            label: occurrence_cause?.label,
                          };
                        }

                        setSearchPayload((prevState) => ({
                          ...prevState,
                          occurrence_cause: occurrenceCause,
                          stationary_asset: "",
                          linear_asset_id: "",
                          linear_asset_start: "",
                          linear_asset_end: "",
                          vehicle: "",
                        }));
                        setOccurrenceCauseFields(
                          occurrence_cause?.value?.occurrence_cause_fields
                        );
                      }}
                      options={
                        Array.isArray(operatorOccurrenceCauses?.data) &&
                        Object.values(
                          operatorOccurrenceCauses?.data?.reduce((acc, item) => {
                            const type = item.occurrence_cause.type;
                            if (!acc[type]) {
                              acc[type] = {
                                label: type,
                                options: [],
                              };
                            }
                            acc[type].options.push({
                              value: item,
                              label: item.occurrence_cause.name,
                            });
                            return acc;
                          }, {})
                        )
                      }
                    />
                  </div>
                  <SingleSelect
                    placeholder={"Waiver Status"}
                    value={searchPayload.waiver_status}
                    isLoading={scheduleTypes.isLoading}
                    handleOnChange={(option) =>
                      setSearchPayload({ ...searchPayload, waiver_status: option })
                    }
                    options={WAIVER__STATUS_OPTIONS.map((option) => {
                      return { value: option.value, label: option.label };
                    })}
                  />
                  Primary
                  <Checkbox
                    value={searchPayload.primary}
                    handleOnChange={(event) =>
                      setSearchPayload({
                        ...searchPayload,
                        primary: event,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Trip</label>
                </div>
                <div className="flex gap-3 flex-wrap">
                  <TextField
                    className="w-20"
                    placeholder={"Trip"}
                    value={searchPayload.trip_name}
                    handleOnChange={(event) =>
                      setSearchPayload({
                        ...searchPayload,
                        trip_name: event.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <DynamicOccurrenceCauseFormFields
                locations={locations}
                occurrencePayload={searchPayload}
                setOccurrencePayload={setSearchPayload}
                stationaryAssets={
                  stationaryAssets && formatStationaryAssets(stationaryAssets)
                }
                handleFieldChange={(field, value) =>
                  handleValuesFieldChange(field, value, setSearchPayload, {}, {})
                }
                vehicleSearchPayload={vehicleSearchPayload}
                setVehicleSearchPayload={setVehicleSearchPayload}
                searchingVehicle={searchingVehicle}
                handleVehicleLookUp={handleVehicleLookUp}
                occurrenceCause={
                  searchPayload.occurrence_cause && searchPayload.occurrence_cause
                }
                linearAssetSelectionRequired={true}
                selectedOperator={selectedOperator}
                linearAssets={linearAssets}
                forceShowLinearAssets={true}
                forceShowAllFields={true}
                manageOccurrences={true}
              />

              {loadingAdvancedFilters && selectedOperator && (
                <div className="w-full h-48 skeleton"></div>
              )}

              {!loadingAdvancedFilters && selectedOperator && (
                <div className="max-sm:collapse">
                  <input
                    type="checkbox"
                    className="min-h-0 sm:hidden"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <div className="collapse-title py-3 px-0 link text-center text-primary min-h-0 sm:hidden">
                    {isChecked ? "Hide" : "Show"} Advanced Filters
                  </div>
                  <div className="collapse-content flex flex-col gap-4 p-0 sm:visible">
                    <div className="divider m-0 h-px"></div>

                    <div className="flex flex-col gap-4">
                      <h3 className="m-0 leading-7">Advanced Filters</h3>
                      <DynamicFormFields
                        fields={combinedFormFields}
                        payload={{
                          ...operatorTripOccurrenceFieldsPayload,
                          ...operatorTripFieldsPayload,
                          ...occurrenceCauseValuesPayload,
                        }}
                        handleFieldChange={handleFieldChange}
                        hideNumberFields={true}
                        labelOnSide={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="divider m-0 h-px"></div>

            <div className="card-footer px-4 py-2">
              <div className="flex gap-3 items-center">
                <button
                  className="btn btn-ghost text-error flex-grow sm:flex-grow-0"
                  disabled={!selectedOperator || loadingSearchResults}
                  onClick={() => {
                    handleResetSearch();
                    setSearchResultsTab("delay");
                  }}
                >
                  <FontAwesomeIcon icon={faRedo} />
                  Reset
                </button>
                <button
                  className="btn btn-primary flex-grow sm:flex-grow-0"
                  disabled={!selectedOperator || loadingSearchResults}
                  onClick={() => handleSearch()}
                >
                  Search
                  {loadingSearchResults && <LoadingSpinner />}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mt-4">
          <div className="card card-compact bg-base-100 border border-base-300 prose">
            <ManageOccurrencesTable
              data={searchResults.results}
              operator_trip_fields={operatorTripFields.data}
              selectedOperator={selectedOperator}
              sort={searchPayload.sort}
              sort_by={searchPayload.sort_by}
              handleChangeSort={handleChangeSort}
              searchResultsTab={searchResultsTab}
              handleSetSearchResultsTab={handleSetSearchResultsTab}
              setDelaysDrawerIsOpen={setDelaysDrawerIsOpen}
              setUnusualOccurrencesDrawerIsOpen={setUnusualOccurrencesDrawerIsOpen}
              setOccurrenceToBeEditedProp={setOccurrenceToBeEditedProp}
              handleClickTableRow={handleClickTableRow}
              loadingSearchResults={loadingSearchResults}
            />
            {searchResults?.results?.length > 0 && (
              <Pagination
                searchResults={searchResults}
                handleChangePage={handleChangePage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
