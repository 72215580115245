export default function TextField({
  value,
  handleOnChange,
  placeholder,
  className,
  disabled,
  reference,
}) {
  return (
    <input
      ref={reference}
      type="text"
      className={`input input-bordered input-sm lg:input-md ${className ?? ""}`}
      placeholder={placeholder}
      value={value}
      onChange={(event) => handleOnChange(event)}
      disabled={disabled}
    />
  );
}
